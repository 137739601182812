import ArticleCard from 'Components/article_card_home'
import ErrorPlaceholder from '@fdn/error_placeholder'
import parsingUrl from 'Helpers/parsingUrl'

const Desktop = ({
  is_mobile,
  articleTitle,
  dataArticleCampaign,
  borderImage,
}) => {
  return (
    <>
      <div className="article-campaign">
        <div className="article-campaign-inner-wrapper">
          <div className="row">
            <div className="col-12">
              <h2 className="article-campaign-title">{articleTitle}</h2>
            </div>
            {Array.isArray(dataArticleCampaign) ? (
              dataArticleCampaign.length ? (
                <>
                  <div className="col-7 big-article-wrapper">
                    {dataArticleCampaign.slice(0, 1).map((item, idx) => (
                      <ArticleCard
                        key={idx}
                        numbering={idx}
                        is_mobile={is_mobile}
                        is_sponsored={false}
                        font_title={`Josefin Sans, sans-serif`}
                        article={Object.assign({
                          category: { name: item.category_slug },
                          date: item.created_at,
                          title: item.title,
                          text: item.excerpt,
                          image: parsingUrl(item.image),
                          url: Array.isArray(item.parent_category_slugs)
                            ? `${
                                process.env.NEXT_PUBLIC_ROOT_DOMAIN
                              }/${item.parent_category_slugs[0].replace(
                                /[_]/g,
                                '-',
                              )}${item.url.split('blog')[1]}`
                            : item.url,
                          author: { fullname: item.author.name },
                        })}
                        url_author={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/blog/author/${item.author.username}`}
                        url_category={`${
                          process.env.NEXT_PUBLIC_ROOT_DOMAIN
                        }/blog/category/${item.category_slug.replace(/[_]/g, '-')}`}
                        type={'type-2'}
                        size="big"
                      />
                    ))}
                  </div>
                  <div className="col-5 small-article-wrapper">
                    {dataArticleCampaign.slice(1, 5).map((item, idx) => (
                      <div className="article-wrapper" key={idx}>
                        <ArticleCard
                          numbering={idx}
                          is_mobile={is_mobile}
                          is_sponsored={false}
                          font_title={`Josefin Sans, sans-serif`}
                          article={Object.assign({
                            category: { name: item.category_slug },
                            date: item.created_at,
                            title: item.title,
                            text: item.excerpt,
                            image: parsingUrl(item.image),
                            url: Array.isArray(item.parent_category_slugs)
                              ? `${
                                  process.env.NEXT_PUBLIC_ROOT_DOMAIN
                                }/${item.parent_category_slugs[0].replace(
                                  /[_]/g,
                                  '-',
                                )}${item.url.split('blog')[1]}`
                              : item.url,
                            author: { fullname: item.author.name },
                          })}
                          url_author={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/blog/author/${item.author.username}`}
                          url_category={`${
                            process.env.NEXT_PUBLIC_ROOT_DOMAIN
                          }/blog/category/${item.category_slug.replace(
                            /[_]/g,
                            '-',
                          )}`}
                          type={'type-4'}
                        />
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="col-12">
                  <ErrorPlaceholder
                    is_mobile={is_mobile}
                    icon="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/editorial/no-data-icon.svg"
                    icon_alt="no data icon female daily"
                    title="Sorry :("
                    text="There’s no data to display"
                  />
                </div>
              )
            ) : (
              <div className="col-12">
                <ErrorPlaceholder
                  is_mobile={is_mobile}
                  icon="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/editorial/something-wrong-icon.svg"
                  icon_alt="wrong icon female daily"
                  title="Uh Oh!"
                  text="Something went wrong"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <style>
        {`
          .article-campaign .big-article-wrapper p {
            margin: 0;
          }

          .article-campaign .big-article-wrapper .article-title,
          .article-campaign .big-article-wrapper .article-text,
          .article-campaign .big-article-wrapper .article-author {
            background-color: #FFF;
            width: 78%;
            margin: 0 auto;
          }

          .article-campaign .big-article-wrapper .article-category {
            display: none;
          }

          .article-campaign .big-article-wrapper .article-title {
            padding: 24px 24px 16px 24px;
            margin-top: -60px;
            z-index: 1;
          }

          .article-campaign .big-article-wrapper .article-text {
            padding: 0 24px 16px 24px;
          }

          .article-campaign .big-article-wrapper .article-author {
            padding: 0 24px 24px 24px;
          }
        `}
      </style>
      <style jsx>
        {`
          .article-campaign {
            padding: 20px;
            background-image: url(${borderImage});
            background-size: contain;
            background-repeat: repeat;
          }

          .article-campaign-inner-wrapper {
            background-color: #fff;
            padding: 43px 40px;
          }

          .article-campaign-title {
            font-family: Josefin Sans, sans-serif;
            text-align: center;
            font-size: 36px;
            line-height: 78px;
            color: #000;
            margin: 0 0 59px 0;
          }

          .small-article-wrapper .article-wrapper {
            padding-bottom: 4px;
            border-bottom: 1px solid #dfe3e8;
            margin-bottom: 20px;
          }
        `}
      </style>
    </>
  )
}

export default Desktop
