import Desktop from './desktop'
import Mobile from './mobile'

const CampaignArticle = ({
  is_mobile,
  articleTitle,
  dataArticleCampaign,
  borderImage,
}) => {
  const props = {
    is_mobile: is_mobile || false,
    articleTitle: articleTitle || null,
    dataArticleCampaign: Array.isArray(dataArticleCampaign) ? dataArticleCampaign : [],
    borderImage: borderImage || null,
  }

  if (!is_mobile) {
    return <Desktop {...props} />
  } else {
    return <Mobile {...props} />
  }
}

export default CampaignArticle
