import ArticleCard from 'Components/article_card_home'
import ErrorPlaceholder from '@fdn/error_placeholder'
import parsingUrl from 'Helpers/parsingUrl'

const Mobile = ({
  is_mobile,
  articleTitle,
  dataArticleCampaign,
  borderImage,
}) => {
  return (
    <>
      <div className="article-campaign">
        <div className="article-campaign-inner-wrapper">
          <h3 className="article-campaign-title">{articleTitle}</h3>
          {Array.isArray(dataArticleCampaign) ? (
            dataArticleCampaign.length ? (
              <>
                <div className="big-article-wrapper">
                  {dataArticleCampaign.slice(0, 1).map((item, idx) => (
                    <ArticleCard
                      key={idx}
                      numbering={idx}
                      is_mobile={is_mobile}
                      is_sponsored={false}
                      font_title={`Josefin Sans, sans-serif`}
                      article={Object.assign({
                        category: { name: '' },
                        date: item.created_at,
                        title: item.title,
                        text: item.excerpt,
                        image: parsingUrl(item.image),
                        url: Array.isArray(item.parent_category_slugs)
                          ? `${
                              process.env.NEXT_PUBLIC_ROOT_DOMAIN
                            }/${item.parent_category_slugs[0].replace(
                              /[_]/g,
                              '-',
                            )}${item.url.split('blog')[1]}`
                          : item.url,
                        author: { fullname: item.author.name },
                      })}
                      url_author={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/blog/author/${item.author.username}`}
                      url_category={`${
                        process.env.NEXT_PUBLIC_ROOT_DOMAIN
                      }/blog/category/${item.category_slug.replace(/[_]/g, '-')}`}
                      type={'type-2'}
                      size="big"
                    />
                  ))}
                </div>
                <div className="small-article-wrapper">
                  {dataArticleCampaign.slice(1, 5).map((item, idx) => (
                    <div className="article-wrapper" key={idx}>
                      <ArticleCard
                        numbering={idx}
                        is_mobile={is_mobile}
                        is_sponsored={false}
                        font_title={`Josefin Sans, sans-serif`}
                        article={Object.assign({
                          category: { name: item.category_slug },
                          date: item.created_at,
                          title: item.title,
                          text: item.excerpt,
                          image: parsingUrl(item.image),
                          url: Array.isArray(item.parent_category_slugs)
                            ? `${
                                process.env.NEXT_PUBLIC_ROOT_DOMAIN
                              }/${item.parent_category_slugs[0].replace(
                                /[_]/g,
                                '-',
                              )}${item.url.split('blog')[1]}`
                            : item.url,
                          author: { fullname: item.author.name },
                        })}
                        url_author={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/author/${item.author.username}`}
                        url_category={`${
                          process.env.NEXT_PUBLIC_ROOT_DOMAIN
                        }/blog/category/${item.category_slug.replace(/[_]/g, '-')}`}
                        type={'type-4'}
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <ErrorPlaceholder
                is_mobile={is_mobile}
                icon="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/editorial/no-data-icon.svg"
                icon_alt="no data icon female daily"
                title="Sorry :("
                text="There’s no data to display"
              />
            )
          ) : (
            <ErrorPlaceholder
              is_mobile={is_mobile}
              icon="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/editorial/something-wrong-icon.svg"
              icon_alt="wrong icon female daily"
              title="Uh Oh!"
              text="Something went wrong"
            />
          )}
        </div>
      </div>
      <style>
        {`
          .article-campaign .big-article-wrapper p {
            margin: 0;
          }

          .article-campaign
            .big-article-wrapper
            .fdn-article-card-mobile-type-2 {
            flex-direction: column;
          }

          .article-campaign .big-article-wrapper .article-image {
            width: calc(100% + 24px);
            margin: 0 -12px;
            height: unset;
            max-height: 300px;
          }

          .article-campaign .big-article-wrapper .article-info {
            width: 91%;
            margin: 0 auto;
            margin-top: -30px;
            padding: 16px 12px 8px 12px;
            z-index: 1;
            background-color: #fff;
            text-align: center;
          }

          .article-campaign .big-article-wrapper .article-info .article-title {
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -5%;
          }

          .article-campaign .big-article-wrapper .article-text {
            display: none;
          }

          .fdn-article-card-mobile-type-4 div.article-info a.article-title,
          .fdn-article-card-mobile-type-2 div.article-info a.article-title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        `}
      </style>
      <style jsx>
        {`
          .article-campaign {
            padding: 12px;
            background-image: url(${borderImage});
            background-size: contain;
            background-repeat: repeat;
            margin: 0 -12px;
          }

          .article-campaign-inner-wrapper {
            background-color: #fff;
            padding: 20px 12px;
          }

          .article-campaign .big-article-wrapper {
            margin-bottom: 22px;
          }

          .article-campaign-title {
            font-family: Josefin Sans, sans-serif;
            font-size: 20px;
            margin-bottom: 0 0 19px 0;
            color: #000;
            text-align: center;
          }

          .small-article-wrapper .article-wrapper {
            padding-bottom: 4px;
            margin-bottom: 20px;
          }

          .small-article-wrapper .article-wrapper:nth-last-child(1) {
            margin-bottom: 0;
          }
        `}
      </style>
    </>
  )
}

export default Mobile
