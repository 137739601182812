const parsingUrl = (url) => {
  if(!url) return ""
  if(typeof url !== "string") return "" 

  let regex = /(http|https)/;

  if (regex.test(url)) {
        return url;
  } else {
    return `https:${url}`;
  };
}
export default parsingUrl